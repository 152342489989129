<template>
<div class="container-fliud">
 <footer>
<div class="left">
<div class="heading">
<img src="../../public/Logo/logo1.png" alt="">
<h3> International Bamboo and <br> Rattan Organisation</h3>
</div>
           <p class="footerText">The mission of INBAR is to improve the well-being of producers and users <br> of bamboo and rattan within the context of a sustainable bamboo and rattan resources <br> base by consolidating, coordinating and supporting strategic and adaptive research and development.</p>
        </div>
        <div class="right">
            <ul class="footernav">
                <li><a href="#">Terms</a></li>
                <li><a href="#">Privacy Policy</a></li>
            </ul>
            <small style="margin-left:110px">Copyright Inbar 2020 @ All rights reserved</small>
        </div>
    <br>
    <br>
    </footer>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>
footer{
    margin-top: 10px;
    background-color:#F5F7FA;
    display: flex;
    justify-content: center;
    border-top: 1px solid rgb(196, 196, 196);
    
}

.footernav{
    display: flex;
    justify-content: center;
}
li{
list-style: none;
}
.footernav li a{
    color: #000;
    text-decoration: underline;
    margin: 10px;

}
.footerText{
color: #707070;
text-align: left;
margin-bottom: 50px;
}
.heading{
display: flex;
}
img{
width: 100px;
margin-right: 20px;
}
p{
margin-top: 50px;
}
.right{
    margin-top: 190px;
}
.left{
    margin-top: 70px;
}
</style>