<template>
<div class="container-fluid">
<header>
    <div class="row" id="topNav" >
                      <div class="col-md-8">
                        <div class="logo">
                        <router-link to="/">
                        <img src="../../public/Logo/logo1.png" alt="" style="width:100px; margin-right:10px; margin-left:100px">
                        </router-link>
                                  <h5>Intra-Africa Bamboo Smallholder Farmers <br>Livelihood Development Programme</h5>
                                  </div>
                        </div>
                    <div class="col-md-4">
                    <h5>Integrated Knowledge <br> Management System</h5>
                    </div>
      </div>
    <nav class="navbar navbar-expand-lg navbar-dark"  id="navbarMain">
      <!-- <a class="navbar-brand" href="#">Navbar</a> -->
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="nav-item  dropdown" style="margin-left:100px" >
            <a class="nav-link dropdown-toggle" style="color:#fff" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Theme
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown" >
              <router-link class="dropdown-item" :to="'/componentOne/' + comp.slug" v-for="comp in themes" :key="comp.slug" >{{comp.title}}</router-link>
            </div>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/elibrary" style="color:#fff">eLibrary</router-link>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/mapping" style="color:#fff">Mapping</router-link>
          </li>
       <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" style="color:#fff" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Country
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link class="dropdown-item" :to="'/country/' + coun.country" v-for="coun in countries" :key="coun.country" >{{coun.country}}</router-link>
            </div>
          </li>
      <li class="nav-item">
            <router-link class="nav-link " to="/istories" style="color:#fff">iStories</router-link>
          </li>
        <li class="nav-item">
            <router-link class="nav-link" to="/contact" style="color:#fff">Contact</router-link>
          </li>
         <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" style="color:#fff" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Help
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <router-link class="dropdown-item" to="/faq">FAQ</router-link>
            </div>
          </li>
        <li class="nav-item ">
            <router-link class="nav-link" to="/register" id="registerBtn">Register</router-link>
        </li>
        </ul>

      </div>
    </nav>
</header>
</div>
</template>

<script>
import baseUrl from '../baseUrl'
export default {
  data() {
    return {
      themes:[],
      countries:[],
      error:''
    }
  },
  methods: {
    menu(){
       baseUrl.get('/api/getAllThemesLite')
        .then((res)=>{
          this.themes = res.data
        }).catch((err)=>{
          this.error = err
        })
    },
    coun(){
           baseUrl.get('/api/getCountriesOfActivity')
            .then((res)=>{
              this.countries = res.data
            }).catch((err)=>{
              this.error = err
            })
    }
  },
  mounted() {
    this.menu()
    this.coun()
  }
}
</script>

<style scoped>

header{
position: fixed;
top: 0;
left: 0;
right: 0;
z-index: 999;
margin-bottom: 250px;
}
#topNav{
padding: 10px;
background-color: #fff;
font-family: 'Roboto', sans-serif; 
}
#navbarMain{
    background-color: #98C149;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    padding: 10px; 
}
#registerBtn{
    background-color: #fff;
    color: #98C149;
    padding: 5px;
    border-radius: 5px;
    width: 80px;
    border: none;
    margin-left: 500px;
}
.nav-link{
color: #fff;
}
.dropdown-menu{
padding: 10px;
}
.logo{
display: flex;
}
</style>